$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
animateElementsInView();
	


function animateElementsInView() {
	$('[data-map-load-on-view]').each(function () {
		
		if ( $(this).hasClass('in-view') ) {
			return;	
		}
		
		if ( isElementInViewport($(this)) ) {
			var elementToAnimate = this;
			
			$(elementToAnimate).addClass('in-view'); // log that this element has been seen
			
			
			setTimeout(function () {
				$(elementToAnimate).addClass( $(elementToAnimate).data('animate-in-view') ); // apply the animation class
			}, 300);
			
			
			if ( typeof $(elementToAnimate).data('map-load-on-view') != 'undefined' ) { // only load this map once it's in view
				buildMap(elementToAnimate);
			}
		}
	});
}

function isElementInViewport (el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}
	
	var rect = el.getBoundingClientRect();
	
	var t = rect.y;
	var b = window.innerHeight - rect.y - rect.height;
	var h = window.innerHeight;
	
	if (
		( (t >= 0) && (t <= h) ) ||
		( (b >= 0) && (b <= h) )
	) {
		return true;
	} else {
		return false;
	}
}


function buildMap(theMap) {
	$(theMap).each(function () {
		var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;
		
		if (typeof $(this).data('map-center-coords') != 'undefined') {
			centerLat = parseFloat( $(this).data('map-center-coords').split(',')[0] );
			centerLng = parseFloat( $(this).data('map-center-coords').split(',')[1] );
		} else {
			centerLat = 0;
			centerLng = 0;
		}
		
		zoom = $(this).data('map-zoom') || 8;
		
		if ((typeof projectSettings.mapStyle !== 'undefined') || (projectSettings.mapStyle === false))  {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				
				styles: projectSettings.mapStyle
			});
		} else {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			});
		}
		
	
		if (typeof $(this).data('map-marker-coords') != 'undefined') {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			}
		}
		
		$(this).siblings('[data-map-marker]').each(function () {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			}
		});
		
		if (typeof $(this).data('map-directions') != 'undefined') {
			var directionsService  = new google.maps.DirectionsService();
			var directionsRenderer = new google.maps.DirectionsRenderer();
			directionsRenderer.setMap(map);
			directionsRenderer.setPanel($('#map-directions-data').get(0));
			
			var directionsTo = $(this).data('map-directions-destination');
			
			$('#frmPostcode').on('submit', function() {
				var directionsFrom = jQuery.trim(jQuery('#tbPostcode').val());
				var directionsRequest = {
					origin: directionsFrom,
					destination: directionsTo,
					travelMode: google.maps.TravelMode.DRIVING,
					unitSystem: google.maps.UnitSystem.IMPERIAL
				};
				
				directionsService.route(directionsRequest, function(result, status){        
					if (status == google.maps.DirectionsStatus.OK) {
						marker.setMap(null);
						directionsRenderer.setDirections(result);
					} else {
						alert('Location "' + directionsFrom + '" not found.');
					}
				});
				
				return false;
			});
		}
	});
}
$(document).ready(function () {
	customNewsletterForms();
	initNeighbourhoodMap();
	customCookieBar();
});


// Override
var initHeader = function () {

	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
		  deferTimer;
	  return function () {
		var context = scope || this;

		var now = +new Date,
			args = arguments;
		if (last && now < last + threshhold) {
		  // hold on to it
		  clearTimeout(deferTimer);
		  deferTimer = setTimeout(function () {
			last = now;
			fn.apply(context, args);
		  }, threshhold);
		} else {
		  last = now;
		  fn.apply(context, args);
		}
	  };
	}

	// Detect if the header is over an image slider, if yes then give it a transparent background
	if ( $('.slider').length >= 0 ) {
		setHeaderTransparency();
		$(window).on('scroll', setHeaderTransparency);
	}

	function setHeaderTransparency() {
		if (window.pageYOffset < 150) {
			$('.header').removeClass('header--compact');

			if ( $('.slider').length > 0 ) {
				$('.header').addClass('header--transparent');
			}

		} else {
			$('.header').addClass('header--compact').removeClass('header--transparent');
		}
	}

}



// Override the Other Rooms JS with custom
var initOtherRooms = function () {
	var slidesToShow = 3;

	$('.other-rooms__carousel').each(function () {
		var numberOfRooms = $(this).find('.other-rooms__room').length;

		if ( numberOfRooms > 3 ) {
			$(this).addClass('center-mode');
		} else if (numberOfRooms == 1) {
			$(this).addClass('one-room');
			slidesToShow = 1;
		} else if (numberOfRooms == 2) {
			$(this).addClass('two-rooms');
			slidesToShow = 2;
		}
	});
	
	$('.other-rooms__carousel').on('setPosition', function (event, slick) {
		if ( window.innerWidth >= 1024 ) { // Desktop
			var halfImgHeight = ($('.other-rooms__carousel .slick-active').first().find('img').outerHeight() / 2) + 75;
		} else if ( window.innerWidth >= 640 ) { // Tablet
			var halfImgHeight = $('.other-rooms__carousel .slick-active').first().find('img').outerHeight() / 2;
		} else { // Mobile
			var halfImgHeight = $('.other-rooms__carousel .slick-current').find('img').outerHeight() / 2;
		}
		
		$('.other-rooms__carousel .slick-arrow').css('top', halfImgHeight + 'px');
	});

	$('.other-rooms__carousel').slick({
		slidesToShow: slidesToShow,
		centerMode: true,
		centerPadding: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}


// Override because of new container element
var initAccordions = function () {
	$('.accordion__inner').accordiom({
	});
}



// Fixing the arrow problem
var initGallery = function () {
	$('.gallery__item a').on('click', function () {
		var $clickedThumbnail = $(this);



		// Dynamically load the gallery slider into a reveal popup
		var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-inner"><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-image"><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content grid-x grid-margin-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div></div>';

		modal = modal.replace('{{src}}', $(this).attr('href'));
		modal = modal.replace('{{caption}}', $(this).data('title') || '');

		var cta = $(this).data('description') || '';
		if ( ( typeof $(this).data('book-link') !== 'undefined' ) && ( $(this).data('book-link') !== '' ) ) {
			cta += '<a href="' + $(this).data('book-link') + '" class="button button--primary" target="_blank">' + ($(this).data('book-title') || 'Book Now') + '</a>';
		}
		modal = modal.replace('{{cta}}', cta);

		var $modal = modal;

		$('body').append($modal);

		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});

		$('#gallery__modal').foundation('open');

		$('.gallery__modal-next').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
				$clickedThumbnail.parent().next(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
				$clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});

		$('.gallery__modal-prev').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
				$clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
				$clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});

		return false;
	});

	refreshMasonry();
	$('.gallery').on('filter-changed', function () {
		setTimeout(refreshMasonry, 600);
	});

	Foundation.onImagesLoaded($('.gallery__item img'), refreshMasonry);

	function refreshMasonry() {
		$('.gallery__grid').masonry({
			columnWidth: '.gallery__item-sizer',
			itemSelector: '.gallery__item',
			percentPosition: true,
			horizontalOrder: true
		});
	};

	// Remove the transition class after loading to avoid jumpy thumbnails
	setTimeout(function () {
		$('.gallery__item').removeClass('transition--2000');
	}, 2000);



	// Remove empty filter buttons (if not handled by the plugin)
	$('.filter__buttons button').each(function () {
		if ( $(this).text() == '' ) {
			$(this).remove();
		}
	});
}


function customNewsletterForms() {
	// 2 inputs with the same ID on the same page doesn't work... have to manually trigger the footer checkbox when clicking on the label
	$('.footer .footer__newsletter label').on('click', function () {
		$(this).siblings('input[type="checkbox"]').each( function () {
			$(this).prop("checked", !$(this).prop("checked"));
		});
	});
}

function initNeighbourhoodMap() {

	var $neighbourhoodMaps = $('.js-neighbourhood-map');

    if ($neighbourhoodMaps.length === 0) {
        return;
    }

    $neighbourhoodMaps.each(function() {
        var $neighbourhoodMap = $(this);

        var lat = $neighbourhoodMap.data('lat');
        var lng = $neighbourhoodMap.data('lng');

        var map = new google.maps.Map($neighbourhoodMap.get(0), {
            center: new google.maps.LatLng(lat, lng),
            zoom: 13,
            scrollwheel: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            clickableIcons: false,
            styles: [{featureType:"administrative",elementType:"labels.text.fill",stylers:[{color:"#444444"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#f2f2f2"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"poi.attraction",elementType:"geometry",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"geometry.fill",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"geometry.stroke",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"poi.attraction",elementType:"labels.text",stylers:[{visibility:"off"}]},{featureType:"poi.attraction",elementType:"labels.text.fill",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"labels.text.stroke",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"poi.park",elementType:"geometry",stylers:[{visibility:"on"},{color:"#c9e2be"}]},{featureType:"poi.park",elementType:"geometry.fill",stylers:[{visibility:"on"},{hue:"#4eff00"}]},{featureType:"poi.park",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"poi.park",elementType:"labels.text.fill",stylers:[{visibility:"on"}]},{featureType:"poi.park",elementType:"labels.text.stroke",stylers:[{visibility:"on"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#8dc8e8"},{visibility:"on"}]}]
        });

        // add centre postion marker of the hotel
        var centreMarker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: {
                url: $neighbourhoodMap.data('marker'),
                size: new google.maps.Size(44, 72),
                scaledSize: new google.maps.Size(44, 72)
            },
            cursor: 'drag',
            map: map
        });

        // add other location markers
        var neighbourhoodMarkers = [];

        var $neighbourhoodMarkers = $neighbourhoodMap.siblings('.js-neighbourhood-marker');

        var neighbourhoodMarkers = [];


				var markerImage = {url:'/assets/img/neighbourhood-map-marker-dark-blue.svg', size: new google.maps.Size(28,46), scaledSize: new google.maps.Size(28,46), anchor: new google.maps.Point(14,23)}
				var markerImageActive = {url:'/assets/img/neighbourhood-map-marker-light-blue.svg', size: new google.maps.Size(28,46), scaledSize: new google.maps.Size(28,46), anchor: new google.maps.Point(14,23)};

        $neighbourhoodMarkers.each(function() {
            var $neighbourhoodMarker = $(this);

            // get SVG image from heading
            var svg = $neighbourhoodMarker
            .find('.neighbourhood__marker-heading svg')
            .clone()
            .wrap('<div></div>')
            .parent()
            .html();



            var neighbourhoodMarker = new google.maps.Marker({
                position: new google.maps.LatLng($neighbourhoodMarker.data('lat'), $neighbourhoodMarker.data('lng')),
                icon: markerImage,
                map: map,
                category: $neighbourhoodMarker.data('category')
            });

            neighbourhoodMarkers.push(neighbourhoodMarker);

            neighbourhoodMarker.addListener('click', function() {
                // close any other open markers
                $neighbourhoodMarkers.not($neighbourhoodMarker).removeClass('neighbourhood__marker--active');
                $('.neighbourhood__map-content').removeClass('marker-active');
                // toggle marker slideout on click
                $neighbourhoodMarker.toggleClass('neighbourhood__marker--active');

                $neighbourhoodMap.trigger('marker_change');
            });
						// change marker icon
						/*google.maps.event.addListener(neighbourhoodMarker, 'click', (function(neighbourhoodMarker) {
							return function() {
								$(neighbourhoodMarkers).each(function() {
									$(this)[0].setIcon(markerImage);
								});
								neighbourhoodMarker.setIcon(markerImageActive);
							}
						})(neighbourhoodMarker));*/

            $neighbourhoodMarker.find('.neighbourhood__marker-close').on('click', function() {
                // close marker slideout when clicking close button
                $neighbourhoodMarker.removeClass('neighbourhood__marker--active');

								$(neighbourhoodMarkers).each(function() {
									$(this)[0].setIcon(markerImage);
								});

                $neighbourhoodMap.trigger('marker_change');
            });

            neighbourhoodMarkers.push(neighbourhoodMarker);
        });

        map.addListener('click', function() {
            // close any open markers
            $neighbourhoodMarkers.removeClass('neighbourhood__marker--active');

						/*$(neighbourhoodMarkers).each(function() {
							$(this)[0].setIcon(markerImage);
						});

            $neighbourhoodMap.trigger('marker_change');*/
            $('.neighbourhood__map-content').removeClass('marker-active');
        });

        centreMarker.addListener('click', function() {
            $neighbourhoodMarkers.removeClass('neighbourhood__marker--active');

						/*$(neighbourhoodMarkers).each(function() {
							$(this)[0].setIcon(markerImage);
						});

            $neighbourhoodMap.trigger('marker_change');*/

            $('.neighbourhood__map-content').toggleClass('marker-active');
        })

        $neighbourhoodMap.on('marker_change', function() {
            // close filters dropdown
            $neighbourhoodMap.siblings('.neighbourhood__filters').removeClass('neighbourhood__filters--expanded');

            // check if a marker is open and adjust minimum height to make slideout fit
            var $activeNeighbourhoodMarker = $neighbourhoodMarkers
            .filter(function() {
                return $(this).hasClass('neighbourhood__marker--active');
            })
            .first();

            if ($activeNeighbourhoodMarker.length > 0) {
                $neighbourhoodMap.css('min-height', $activeNeighbourhoodMarker.find('.neighbourhood__marker-content').outerHeight());
            } else {
                $neighbourhoodMap.css('min-height', '');
            }
        });

        google.maps.event.addDomListener(window, 'resize', function() {
            var center = map.getCenter();
            google.maps.event.trigger(map, 'resize');
            map.setCenter(center);
        });

        // open/close filters on mobile
        $neighbourhoodMap
        .parents()
        .find('.js-neighbourhood-filter-toggle')
        .on('click', function() {
            var $neighbourhoodFilterToggle = $(this);
						console.log('filters clicked');
            $neighbourhoodFilterToggle.parent('.neighbourhood__filters').toggleClass('neighbourhood__filters--expanded');
        });

        // filter displayed markers based on chosen filter categories

        var $neighbourhoodCategories = $neighbourhoodMap.parents('.neighbourhood').find('.js-neighbourhood-category');

        $('.neighbourhood__filter-all').on('click', function() {
					$(this).removeClass('off');
					$(this).addClass('on');

					$neighbourhoodCategories.each(function() {
							var $category = $(this);
							$category.prop('checked', false);
							$category.trigger('change');
					})
        })

        $neighbourhoodCategories.on('change', function() {
					var $neighbourhoodCategory = $(this);
					var $otherNeighbourhoodCategories = $neighbourhoodCategories.not($neighbourhoodCategory);

					var isThisCategoryChecked = $neighbourhoodCategory.is(':checked');
					var isEverythingElseChecked = $otherNeighbourhoodCategories.not(':checked').length === 0;

					if (!isThisCategoryChecked && isEverythingElseChecked) {
						// first filter click, so uncheck this one and check everything else
						$neighbourhoodCategory.prop('checked', false);
						$otherNeighbourhoodCategories.prop('checked', true);
					}

					// update visible map markers based on selected categories
					var selectedCategories = [];
					var unSelectedCategories = [];

					$neighbourhoodCategories.each(function() {
						var $category = $(this);

						if ($category.is(':checked')) {
								selectedCategories.push($category.val());
						} else {
								unSelectedCategories.push($category.val());
						}
					});
					
					// If no filters are selected, then show everything and enable the "all" button
					if (selectedCategories.length === 0) {
						$('.neighbourhood__filter-all').removeClass('off').addClass('on');
						selectedCategories = unSelectedCategories;
					} else {
						$('.neighbourhood__filter-all').removeClass('on').addClass('off');
					}

					neighbourhoodMarkers.forEach(function(marker) {
						var svgString = '<svg xmlns="http://www.w3.org/2000/svg" width="27.834" height="45.795" viewBox="0 0 27.834 45.795"><g id="Group_1683" data-name="Group 1683" transform="translate(-235.5 -1329.777)"><path id="Icon_map-map-pin" data-name="Icon map-map-pin" d="M21.26,1.728A13.292,13.292,0,0,0,7.844,14.891C7.844,22.161,21.26,46,21.26,46S34.678,22.161,34.678,14.891A13.294,13.294,0,0,0,21.26,1.728Z" transform="translate(228.156 1328.549)" fill="#2875a0" stroke="#fff" stroke-width="1"/></g></svg>'

						if ( unSelectedCategories.indexOf(marker.category) > -1 ) {

							var decodedIcon = decodeURIComponent(marker.icon.url);

							marker.setIcon({
								url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString),
								size: new google.maps.Size(0, 0),
								scaledSize: new google.maps.Size(0, 0),
								anchor: new google.maps.Point(0, 0)
							});
						}

						if ( selectedCategories.indexOf(marker.category) > -1 ) {
							marker.setIcon({
								url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString),
								size: new google.maps.Size(28, 46),
								scaledSize: new google.maps.Size(28, 46),
								anchor: new google.maps.Point(14, 23)
							})
						}

					});

				// close any open markers by triggering click handler
				$neighbourhoodMarkers.removeClass('neighbourhood__marker--active');
			});

		// initialise any sliders
		$neighbourhoodMarkers.find('.neighbourhood__marker-slider').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true
		});
	});

	$('.neighbourhood__map-content--close').on('click', function() {
		$('.neighbourhood__map-content').removeClass('marker-active');
	});
}


// Override
function pushContentBelowHeader() {
	$('main').css('padding-top', $('.header').outerHeight());
}

function customCookieBar() {
	if ( $('.cookie-bar').length > 0 ) {
		if ( !Cookies.get('accepted_cookies') ) {
			$('.cookie-bar').fadeIn();
		}


		$('.cookie-bar__accept').on('click', function () {
			Cookies.set('accepted_cookies', 'true', {expires: 30});
			$('.cookie-bar').fadeOut();
		});
	}
}


var initAnimateInView = function() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

var animateElementsInView = function() {
	$('[data-animate-in-view]').each(function () {
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') ).addClass('in-view');
		}
	});
}

var isElementInViewport = function(el) {
	var rect = el[0].getBoundingClientRect();
	var triggerPoint = rect.top + 100;
	
	if ( 
		( triggerPoint >= 0 ) &&
		( triggerPoint <= window.innerHeight )
	) {
		return true;
	} else {
		return false;
	}
}

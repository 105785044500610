// This file contains customised Architecture settings.
// Refer to bower_components/Architecture-Blueprint/markup/js/architecture.js for full list of options

var projectSettings = {
	inlineSVG: [
		'.header__mobile-call img',
		'.footer__social img'
	],
	slider: {
		speed: 4500,
		animationSpeed: 1500,
		arrows: false,
		pagination: false,
		paginationArrows: false
	},
	sliderFullHeightScrollButton: false,
	mapStyle: [{featureType:"administrative",elementType:"labels.text.fill",stylers:[{color:"#444444"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#f2f2f2"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"poi.attraction",elementType:"geometry",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"geometry.fill",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"geometry.stroke",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"poi.attraction",elementType:"labels.text",stylers:[{visibility:"off"}]},{featureType:"poi.attraction",elementType:"labels.text.fill",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"labels.text.stroke",stylers:[{visibility:"on"}]},{featureType:"poi.attraction",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"poi.park",elementType:"geometry",stylers:[{visibility:"on"},{color:"#c9e2be"}]},{featureType:"poi.park",elementType:"geometry.fill",stylers:[{visibility:"on"},{hue:"#4eff00"}]},{featureType:"poi.park",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"poi.park",elementType:"labels.text.fill",stylers:[{visibility:"on"}]},{featureType:"poi.park",elementType:"labels.text.stroke",stylers:[{visibility:"on"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#8dc8e8"},{visibility:"on"}]}]
};
